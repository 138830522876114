import React, { useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { nanoid } from 'nanoid';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { getCookiePopup, triggerCookiePopupOpen } from 'utils/browser';
import { FooterQuery } from './models';
import './styles.scss';

export const Footer = () => {
  const [cookiePopup, setCookiePopup] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const id = setTimeout(() => {
      setCookiePopup(getCookiePopup());
    }, 500);

    return () => clearTimeout(id);
  }, []);

  const {
    footer: { logo, navbar, copyright },
    brandSettings: { brandName },
  }: FooterQuery = useStaticQuery(graphql`
    {
      brandSettings {
        brandName
      }
      footer {
        copyright
        logo {
          fallbackUrl
          fluid {
            srcSet
            base64
          }
        }
        navbar {
          properties {
            label
            url {
              url
            }
            openCookiePopup
            withoutanchor
          }
        }
      }
    }
  `);

  return (
    <footer className="footer__container">
      <div className="footer__logo">
        <UmbracoImage image={logo} alt={brandName} className="footer__img" />
      </div>
      <div className="footer__nav">
        {navbar.map(({ properties }) => {
          const { label, url, openCookiePopup, withoutanchor } = properties;
          const [footerItem] = url;
          const key = nanoid();

          return openCookiePopup ? (
            <button
              type="button"
              className="footer__link"
              onClick={triggerCookiePopupOpen(cookiePopup)}
            >
              {label}
            </button>
          ) : withoutanchor ? (
            <span
              className="footer__link footer-disclaimer"
            >
              {label}
            </span>
          ) :  (
            footerItem?.url?.includes('https') ? (
              <Link to={footerItem.url} key={key} className="footer__link" target="_blank">
                {label}
              </Link>
            ) :
              <Link to={footerItem.url} key={key} className="footer__link">
                {label}
              </Link>
          );
        })}
      </div>
      <DangerouslySetInnerHtml className="footer__copyright" html={copyright} />
    </footer>
  );
};

export default Footer;